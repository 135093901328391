<template>
    <div class="floating-chat mb-5 mb-md-5 mr-md-3" @click="openElement">
        <i class="fa fa-comments" aria-hidden="true"></i>
        <div class="badge bg-danger" v-if="unreadMessages > 0">{{ unreadMessages }}</div>
        <div class="chat">
            <div class="header p-0 bg-primary">
                <span class="title d-flex justify-content-center align-items-center">What's on your mind?</span>
                <button @click.stop="closeElement">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </button>
            </div>
            <div>
            </div>
            <ul class="messages ml-1">
                <li v-for="(message, index) in messages" :key="index"
                    :class="['other w-100 text-wrap text-black', message.type, message.severityClass]">
                    <img src="img/theme/team-1.jpg" alt="Avatar" class="avatar">
                    {{ message.text }}
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { connect, disconnect } from './websocketService';
export default {
    data() {
        return {
            messages: [],
            unreadMessages: 0,
            isChatOpen: false,
            element: null,
        };
    },
    computed: {
        unreadMessages() {
            return this.messages.filter(message => !message.isRead).length;
        },
    },
    mounted() {
        this.element = this.$el;
        this.loadMessagesFromSession();
        if (!localStorage.getItem('chatID')) {
            localStorage.setItem('chatID', this.createUUID());
        }
        connect(this.onMessageReceived);
        setTimeout(() => {
            this.element.classList.add('enter');
        }, 1000);
    },
    methods: {
        onMessageReceived(message) {
            const icon = this.element.querySelector('i');
            const currentTime = new Date().getTime();
            const messages = JSON.parse(message)
            messages.forEach(message => {
                const severityClass = this.getSeverityClass(message.notificationSeverity);
                this.messages.push({
                    type: 'other',
                    text: message.message,
                    time: currentTime,
                    isRead: this.isChatOpen,
                    severityClass: severityClass,
                });
            });
            if (!this.isChatOpen) {
                if (icon) {
                    icon.classList.add('shake');
                    setTimeout(() => {
                        icon.classList.remove('shake');
                    }, 500);
                }
            }
            this.clearExpiredMessages();
            this.saveMessagesToSession();
            this.scrollToBottom(this.element.querySelector('.messages'));
        },
        getSeverityClass(severity) {
            switch (severity) {
                case 'CRITICAL':
                    return 'bg-danger-chat';
                case 'INFO':
                    return 'bg-info-chat';
                case 'SUCCESS':
                    return 'bg-success-chat';
                default:
                    return '';
            }
        },
        openElement() {
            const messages = this.element.querySelector('.messages');
            const icon = this.element.querySelector('i');
            const badge = this.element.querySelector('.badge');
            if (icon) icon.style.display = 'none';
            if (badge) badge.style.display = 'none';
            this.isChatOpen = true;
            this.messages.forEach(message => {
                message.isRead = true;
            });
            this.element.classList.add('expand');
            this.element.querySelector('.chat').classList.add('enter');
            this.scrollToBottom(messages);
        },
        closeElement() {
            const chatElement = this.element.querySelector('.chat');
            const icon = this.element.querySelector('i');
            const badge = this.element.querySelector('.badge');
            chatElement.classList.remove('enter');
            if (icon) icon.style.display = 'block';
            if (badge) badge.style.display = 'block';
            this.isChatOpen = false;
            this.element.classList.remove('expand');
            setTimeout(() => {
                chatElement.classList.remove('enter');
                this.element.addEventListener('click', this.openElement);
            }, 500);
        },
        createUUID() {
            let s = [];
            const hexDigits = "0123456789abcdef";
            for (let i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = "4";
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
            s[8] = s[13] = s[18] = s[23] = "-";
            return s.join("");
        },
        scrollToBottom(element) {
            element.scrollTop = element.scrollHeight;
        },
        saveMessagesToSession() {
            sessionStorage.setItem('messages', JSON.stringify(this.messages));
        },
        loadMessagesFromSession() {
            const savedMessages = sessionStorage.getItem('messages');
            if (savedMessages) {
                this.messages = JSON.parse(savedMessages);
            }
        },
        clearExpiredMessages() {
            const hoursLimit = new Date().getTime() - 7200000;
            this.messages = this.messages.filter(message => message.time >= hoursLimit);
            this.saveMessagesToSession();
        },
    },
    beforeDestroy() {
        disconnect();
    },
};
</script>