<template>
  <div class="content">
    <div class="container-fluid mt-4">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template v-slot:header>
            <h3 class="mb-0">Instrument Table</h3>
            <p class="text-sm mb-0">
              This is a client side example of paginated tables using element-ui tables.
            </p>
          </template>
          <div>
            <div class="row flex-wrap mb-2 mb-md-0">
              <div class="col-12 col-lg-6 mb-2 d-flex justify-content-start">
                <el-select class="w-100 w-lg-50 ml-2 mr-2 ml-lg-0 mr-lg-9 ml-0 px-lg-4 pagination-select"
                  v-model="perPage" placeholder="Per page" @change="handlePerPageChange">
                  <el-option class="select-primary" v-for="item in perPageOptions" :key="item" :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>
              <div class="col-12 col-lg-6 d-flex justify-content-lg-end mb-2  ">
                <input v-model="tempSearchQuery" class="form-control ml-2 ml-lg-9 form-control-md mb-lg-0" type="search"
                  placeholder="Search instruments" />
                <base-button class="ml-2 px-3 mr-2" size="md" type="primary"
                  @click="handleSearchChange">Search</base-button>
              </div>
            </div>
            <el-table :data="queriedDataWithFullName" row-key="id" header-row-class-name="thead-light"
              @sort-change="handleSortChange">
              <el-table-column label="slNo" prop="slNo" min-width="120px">
              </el-table-column>
              <el-table-column label="instrumentId" prop="instrumentId" min-width="180px" sortable>
              </el-table-column>
              <el-table-column label="Name" min-width="300px" sortable>
                <template v-slot:default="props">
                  <div class="font-weight-bold ">
                    {{ props.row.code }} ( {{ props.row.name }})
                  </div>
                  <div>
                    {{ props.row.exchangeToken }}
                    <span class="px-2 badge badge-sm" :class="`badge-primary`">
                      {{ props.row.exchangeName }}
                    </span>
                  </div>
                  <div> {{ props.row.assetTypeName }}</div>
                </template>
              </el-table-column>
              <el-table-column label="status" prop="status" min-width="140px">
                <template v-slot:default="props">
                  <div class="col-auto px-0">
                    <span class="badge badge-lg" :class="setStatusColor(props.row.status)">
                      {{ props.row.status }}
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="lotSize" prop="lotSize" min-width="140px" sortable>
              </el-table-column>
              <el-table-column label="expiry" prop="expiry" min-width="160px">
                <template v-slot:default="props">
                  <span v-if="props.row.expiry">{{ props.row.expiry }}</span>
                  <span v-else>NA</span>
                </template>
              </el-table-column>
              <el-table-column label="broker" prop="brokerName" min-width="140px" sortable>
              </el-table-column>
              <el-table-column label="instrumentType" prop="instrumentTypeName" min-width="180px" sortable>
              </el-table-column>
            </el-table>
          </div>
          <template v-slot:footer>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p v-if="isSuccess" class="card-category">
                  Showing {{ from + 1 }} to {{ Math.min(to, total) }} of {{ total }} entries
                </p>
              </div>
              <base-pagination v-if="isSuccess" class="pagination-no-border" v-model="currentPage" :per-page="perPage"
                :total="total" :page-count="totalPages" @update:modelValue="handleFetchNextPage">
              </base-pagination>
            </div>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BasePagination from "@/components/BasePagination";
export default {
  components: {
    BasePagination,
    RouteBreadCrumb,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
  },
  computed: {
    pagedData() {
      return this.tableData;
    },
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          let rowValue = row[key].toString();
          if (rowValue.includes && rowValue.includes(this.searchQuery)) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });
      return result;
    },
    queriedDataWithFullName() {
      const startIndex = this.from + 1;
      return this.queriedData.map((item, index) => ({
        ...item,
        slNo: startIndex + index,
      }));
    },
    from() {
      return (this.currentPage - 1) * this.perPage;
    },
    to() {
      return Math.min(this.from + this.perPage, this.total);
    },
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
      perPageOptions: [5, 10, 25, 50],
      totalElements: 0,
      totalPages: 0,
      total: 0,
      searchQuery: '',
      tempSearchQuery: '',
      direction: "DESC",
      sortedColumn: "code",
      propsToSearch: ["code"],
      tableData: [],
      searchedData: [],
      isSuccess: false,
    };
  },
  methods: {
    setStatusColor(status) {
      return status == "ACTIVE" ? "badge-success" : "badge-warning";
    },
    setTable(pageNo, pageSize, sortBy, direction, filter) {
      let data = {
        pageNo: pageNo - 1,
        pageSize: pageSize,
        sortBy: sortBy,
        direction: direction,
        filter: filter,
      };
      this.$store.dispatch("instrumentStore/getInstrumentDetails", data, { root: true })
        .then(response => {
          this.tableData = response.server.data.instrumentBean;
          this.totalElements = this.tableData.length;
          this.currentPage = response.server.data.currentPage + 1;
          this.totalPages = response.server.data.totalPages;
          this.total = response.server.data.totalElements;
          this.isSuccess = this.tableData.length > 0;
        })
        .catch(error => {
          console.log(error);
        });
    },
    handleSortChange({ prop, order }) {
      let sortProp = prop || 'code';
      let sortOrder = order || 'ascending';
      this.setTable(this.currentPage, this.perPage, sortProp, this.mapSortOrder(sortOrder), this.searchQuery);
    },
    mapSortOrder(order) {
      const orderMap = {
        "ascending": "ASC",
        "descending": "DESC",
      };
      return orderMap[order];
    },
    handlePerPageChange(newPerPage) {
      this.perPage = newPerPage;
      this.setTable(this.currentPage, this.perPage, this.sortedColumn, this.direction, this.searchQuery);
    },
    handleFetchNextPage(newPage) {
      this.currentPage = newPage;
      this.setTable(this.currentPage, this.perPage, this.sortedColumn, this.direction, this.searchQuery);
    },
    handleSearchChange() {
      this.searchQuery = this.tempSearchQuery;
      this.setTable(1, this.perPage, this.sortedColumn, this.direction, this.searchQuery);
    },
    inputControl() {
      if (this.searchQuery.length > 0) {
        this.setTable(1, this.perPage, this.sortedColumn, this.direction, this.searchQuery);
      } else {
        this.setTable(1, this.perPage, this.sortedColumn, this.direction, NULL);
      }
    },
  },
  mounted() {
    this.setTable(this.currentPage, this.perPage, this.sortedColumn, this.direction, this.searchQuery);
  }
};
</script>
<style></style>
