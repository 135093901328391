import requestCommon from "@/components/web-interface/requestCommon";
const state = {
  instrumentDetails: null,
};
const actions = {
  getInstrumentDetails(context, data) {
    let fill = "";
    if (data.filter) {
      fill = "filter=" + data.filter
    }
    return requestCommon.getInfo(
      "/instruments?" + fill + "&direction=" + data.direction + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&sortBy=" + data.sortBy,
      data,
      null,
      context,
      "getInstrumentDetailsSuccessHandler",
      "getInstrumentDetailsErrorHandler"
    );
  },
}
const mutations = {
  getInstrumentDetailsSuccessHandler(state, data) {
    console.log('Received data:', data);
    state.instrumentDetails = data.server.data;
  },
  getInstrumentDetailsErrorHandler(context, error) {
    console.log(error);
  }
};
const getters = {};
export const instrumentStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}