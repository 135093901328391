import requestCommon from "@/components/web-interface/requestCommon";
const state = {
  userDetails: null,
};
const actions = {
  getUserDetails(context, data) {
    let fill = "";
    if (data.filter) {
      fill = "filter=" + data.filter
    }
    return requestCommon.getInfo(
      "/users?" + fill + "&direction=" + data.direction + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&sortBy=" + data.sortBy,
      data,
      null,
      context,
      "getUserDetailsSuccessHandler",
      "getuserDetailsErrorHandler"
    );
  },
}
const mutations = {
  getUserDetailsSuccessHandler(state, data) {
    console.log('Received data:', data);
    state.userDetails = data.server.data;
  },
  getUserDetailsErrorHandler(context, error) {
    console.log(error);
  }
};
const getters = {};
export const userStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}