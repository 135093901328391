<template>
  <div class="content">
    <div class="container-fluid mt-4">
      <div>
        <card class="no-border-card mt-1" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template v-slot:header>
            <div class="row align-items-center py-4">
              <div class="col-lg-6 col-8">
                <h3 class="mb-0">Buttons page</h3>
                <p class="text-sm mb-0">
                  This is a client side example of paginated tables using element-ui
                  tables.
                </p>
              </div>
            </div>
          </template>
          <div class=" container-fluid">
            <div class="row ">
              <div class="mx-auto ">
                <div class="mt-2  row">
                  <div class="col-lg-4 ">
                    <div class="card h-100 ">
                      <div class="text-center card-body">
                        <div class="h-50">
                          <p class="card-text mb-0  font-weight-bold">Place an order </p>
                          <p class="card-text text-sm ">Execute buy or sell trade </p>
                        </div>
                        <div class="pt-1">
                          <base-button type="primary" class="w-100">
                            <div class="row">
                              <div class="col-5 d-flex justify-content-end pr-0 align-items-end"><span
                                  class="text-white"><i class="fas fa-chart-line"></i></span></div>
                              <div class="col-7 d-flex justify-content-start pl-0 align-items-start"> <span
                                  class="ml-1 text-white">PlaceOrder</span></div>
                            </div>
                          </base-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" col-lg-4 mt-md-0">
                    <div class="card h-100 ">
                      <div class="text-center card-body">
                        <div class="h-50">
                          <p class="card-text mb-0 font-weight-bold">Close open positions</p>
                          <p class="card-text text-sm">close all open positions for the selected individual</p>
                        </div>
                        <div class="pt-1">
                          <base-button type="warning" class="w-100">
                            <div class="row">
                              <div class="col-5 d-flex justify-content-end pr-0 align-items-end"> <span
                                  class="text-white"><i class="fas fa-times"></i></span></div>
                              <div class="col-7 d-flex justify-content-start pl-0 align-items-start"> <span
                                  class="ml-1 text-white ">Exit All</span></div>
                            </div>
                          </base-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 mt-md-0">
                    <div class="card h-100 ">
                      <div class="text-center card-body">
                        <div class="h-50">
                          <p class="card-text mb-0 font-weight-bold">Close all open positions</p>
                          <p class="card-text text-sm">close all open orders for all customers</p>
                        </div>
                        <div class="pt-1">
                          <base-button type="default" class="w-100">
                            <div class="row">
                              <div class="col-5 d-flex justify-content-end pr-0 align-items-end">
                                <span class="text-white"><i class="fas fa-sign-out-alt"></i></span>
                              </div>
                              <div class="col-7 d-flex justify-content-start pl-0 align-items-start">
                                <span class="ml-1 text-white">Exit All Orders</span>
                              </div>
                            </div>
                          </base-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-lg-5  row">
                  <div class="col-lg-4 mb-lg-4 mt-md-0">
                    <div class="card h-100">
                      <div class="text-center card-body">
                        <div class="h-50">
                          <p class="card-text mb-0 font-weight-bold">Play and Pause</p>
                          <p v-if=!isPlaying class="card-text text-sm">Start or resume trade entries</p>
                          <p v-else class="card-text text-sm">Temporarily stop trade entries</p>
                        </div>
                        <div class=" pt-1 ">
                          <base-button @click="togglePlayPause" :class="[buttonClass]" type=toggleType class="w-100">
                            <div class="row">
                              <div class="col-5 d-flex justify-content-end pr-0 align-items-end">
                                <span class=" text-white ">
                                  <i :class="toggleIcon"></i>
                                </span>
                              </div>
                              <div class="col-7 d-flex justify-content-start pl-0 align-items-start">
                                <span class="ml-1 text-white pl-0">{{ toggleText }}</span>
                              </div>
                            </div>
                          </base-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class=" mb-4 col-lg-4 mt-md-0">
                    <div class="card h-100 ">
                      <div class="text-center card-body">
                        <div class="h-50">
                          <p class="card-text mb-0 font-weight-bold">Turn off</p>
                          <p class="card-text text-sm">Stop production completely</p>
                        </div>
                        <div class="pt-1">
                          <base-button icon type="danger" class=" w-100">
                            <div class="row">
                              <div class="col-5 d-flex justify-content-end pr-0 align-items-end">
                                <span class="text-white"><i class="fas fa-power-off"></i></span>
                              </div>
                              <div class="col-7 d-flex justify-content-start pl-0 align-items-start">
                                <span class="ml-1 text-white">Turn Off</span>
                              </div>
                            </div>
                          </base-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isPlaying: false,
    }
  },
  computed: {
    toggleIcon() {
      return this.isPlaying ? 'fas fa-pause' : 'fas fa-play';
    },
    toggleText() {
      return this.isPlaying ? 'Pause' : 'Play';
    },
    toggleType() {
      return this.isPlaying ? 'bg-green' : 'bg-purple';
    },
    buttonClass() {
      return this.isPlaying ? 'bg-purple' : 'bg-green';
    },
  },
  methods: {
    togglePlayPause() {
      this.isPlaying = !this.isPlaying;
    },
  },
};
</script>

