<template>
  <router-view></router-view>
  <floatingChat></floatingChat>
</template>

<script>
import floatingChat from "./views/System/FloatingChat.vue";
export default {
  components: {
    floatingChat
  },
};
</script>
