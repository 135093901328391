import requestCommon from "@/components/web-interface/requestCommon";
const state = {
  orderDetails: null,
};
const actions = {
  getOrderDetails(context, data) {
    let fill = "";
    if (data.filter) {
      fill = "filter=" + data.filter
    }
    return requestCommon.getInfo(
      "/orders?" + fill + "&direction=" + data.direction + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&sortBy=" + data.sortBy,
      data,
      null,
      context,
      "getOrderDetailsSuccessHandler",
      "getOrderDetailsErrorHandler"
    );
  },
  getOrderById(context, data) {
    return requestCommon.getInfo(
      "/orders" + "/" + data.id,
      data,
      null,
      context,
      "getOrderByIdSuccessHandler",
      "getOrderByIdErrorHandler"
    );
  },
}
const mutations = {
  getOrderDetailsSuccessHandler(state, data) {
    state.orderDetails = data.server.data;
  },
  getOrderDetailsErrorHandler(context, error) {
    console.log(error);
  },
  getOrderByIdSuccessHandler(state, data) {
    state.orderDetails = data.server.data;
  },
  getOrderByIdErrorHandler(context, error) {
    console.log(error);
  },
};
const getters = {};
export const orderStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}