import Stomp from 'stompjs';

let stompClient = null;
let isConnected = false;

export function connect(onMessageReceived) {
    const socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_BASE_URL);
    stompClient = Stomp.over(socket);

    stompClient.debug = (str) => { console.log(str); };

    stompClient.connect({}, () => {
        isConnected = true;
        stompClient.subscribe('/firefly/events', (message) => {
            onMessageReceived(JSON.parse(message.body));
        });
    }, (error) => {
        console.error('Connection error:', error);
    });
}

export function send(command) {
    if (isConnected && stompClient) {
        console.log("Sending command: " + command);
        stompClient.send('/app/status', {}, JSON.stringify({ command }));
    } else {
        console.error('Cannot send message: WebSocket is not connected');
    }
}

export function disconnect() {
    if (stompClient !== null) {
        stompClient.disconnect(() => {
            console.log('Disconnected');
        });
    }
}