import Stomp from 'stompjs';

let stompClient = null;

export function connect(onMessageReceived) {
    const socket = new WebSocket(process.env.VUE_APP_WEBSOCKET_BASE_URL);
    stompClient = Stomp.over(socket);

    stompClient.connect({}, () => {
        stompClient.subscribe('/topic/messages', (message) => {
            onMessageReceived(message.body);
        });
    });
}

export function disconnect() {
    if (stompClient !== null) {
        stompClient.disconnect();
    }
}