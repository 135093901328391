import { createStore } from "vuex";
import { userStore } from "./user/user.store";
import { portfolioStore } from "./portfolio/portfolio.store";
import { instrumentStore } from "./instrument/instrument.store";
import { orderStore } from "./order/order.store";
import { brokerStore } from "./broker/broker.store";
import { holidayStore } from "./holiday/holiday.store";
import { variationStore } from "./variation/variation.store";
export default createStore({
    modules: {
        userStore,
        portfolioStore,
        instrumentStore,
        orderStore,
        brokerStore,
        holidayStore,
        variationStore,
    },
    getters: {}
});
