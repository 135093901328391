<template>
  <div :class="['card-pricing bg-white border-0 mb-4', cardClass]">
    <div :class="['pt-4 pb-3 text-center card-header', headerClass]">
      <h4 class="text-uppercase text-center ls-1 py-3 mb-0">{{ header }}</h4>
    </div>
    <ul class="list-unstyled my-2">
      <li v-for="(feature, index) in features" :key="index" class="border-bottom py-2">
        <div class="row flex-grow-1 d-flex justify-content-between">
          <div class="col-5 pl-5">{{ toCamelCase(feature.name) }}</div>
          <div class="col-4 border-left">{{ feature.value }}</div>
          <div class="col-3 border-left  d-flex justify-content-center align-items-center">
            <base-button :class="buttonClass" size="sm">Edit</base-button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "VariationCard",
  props: {
    cardClass: {
      type: String,
      default: ""
    },
    headerClass: {
      type: String,
      default: ""
    },
    buttonClass: {
      type: String,
      default: ""
    },
    header: {
      type: String,
      default: ""
    },
    features: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    toCamelCase(str) {
      return str.toLowerCase().replace(/(_\w)/g, match => match[1].toUpperCase());
    },
  }

};
</script>
<style scoped></style>