import axios from "axios";

function getHttpClient() {
  return axios.create({
    baseURL: process.env.VUE_APP_FIREFLY_API_URL,
  });
}
function getInfo(
  requestUrl,
  requestParameter,
  requestData,
  context,
  successHandlerName,
  errorHandlerName
) {
  return new Promise((resolve, reject) => {
    getHttpClient()
      .get(requestUrl)
      .then((response) => {
        onSuccess(
          response,
          requestData,
          requestParameter,
          context,
          successHandlerName,
          resolve
        );
      })
      .catch(function (error) {
        onError(
          error,
          requestData,
          requestParameter,
          context,
          errorHandlerName,
          reject
        );
      });
  });
}
function onSuccess(
  response,
  requestData,
  requestParameter,
  context,
  commitMethodName,
  resolve
) {
  let data = {
    server: response,
    requestData: requestData,
    parameter: requestParameter,
  };
  if (context) {
    if (commitMethodName) context.commit(commitMethodName, data);
  }
  resolve(data);
}
function onError(
  errorResponse,
  requestData,
  requestParameter,
  context,
  commitMethodName,
  reject
) {
  let data = {
    server: errorResponse,
    requestData: requestData,
    parameter: requestParameter,
  };
  if (
    errorResponse &&
    errorResponse.response &&
    errorResponse.response.status === 401 &&
    context
  )
    if (commitMethodName) {
      context.commit(commitMethodName, data);
    }
  reject(data);
}
export default {
  getInfo,
};