import requestCommon from "@/components/web-interface/requestCommon";
const state = {
  variationDetails: null,
};
const actions = {
  getVariationDetails(context, data) {
    
    return requestCommon.getInfo(
      "/variations/" + data.variationId + "/" + data.type,
      data,
      null,
      context,
      "getVariationDetailsSuccessHandler",
      "getVariationDetailsErrorHandler"
    );
  },
}
const mutations = {
  getVariationDetailsSuccessHandler(state, data) {
    console.log('Received data:', data);
    state.variationDetails = data.server.data;
  },
  getVariationDetailsErrorHandler(context, error) {
    console.log(error);
  }
};
const getters = {};
export const variationStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}