import requestCommon from "@/components/web-interface/requestCommon";
const state = {
  portfolioDetails: null,
};
const actions = {
  getPortfolioDetails(context, data) {
    let fill = "";
    if (data.filter) {
      fill = "filter=" + data.filter
    }
    return requestCommon.getInfo(
      "/portfolios?" + fill + "&direction=" + data.direction + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&sortBy=" + data.sortBy,
      data,
      null,
      context,
      "getPortfolioDetailsSuccessHandler",
      "getPortfolioDetailsErrorHandler"
    );
  },
}
const mutations = {
  getPortfolioDetailsSuccessHandler(state, data) {
    console.log('Received data:', data);
    state.portfolioDetails = data.server.data;
  },
  getPortfolioDetailsErrorHandler(context, error) {
    console.log(error);
  }
};
const getters = {};
export const portfolioStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}