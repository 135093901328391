<template>
    <div class="content">
        <div class="container-fluid mt-4">
            <div>
                <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
                    <template v-slot:header>
                        <div class="row align-items-center py-4">
                            <div class="col-lg-6 col-8">
                                <h3 class="mb-0">Variations</h3>
                                <p class="text-sm mb-0">
                                    This is a client side example of paginated tables using element-ui
                                    tables.
                                </p>
                            </div>
                        </div>
                    </template>
                    <div class="row">
                        <div class="col-12 col-md-6 mb-2 mb-sm-4 ">
                            <div class="card ml-md-4 h-100 ">
                                <variation-card cardClass="card-pricing border-0 mb-4" headerClass="bg-primary"
                                    buttonClass="bg-primary border-primary" header="Excecution variables"
                                    :features="executionVariables" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-2 mb-sm-4">
                            <div class="card mr-md-4 h-100">
                                <variation-card cardClass="card-pricing border-0 mb-4" headerClass="bg-info"
                                    buttonClass="bg-info border-info" header="Stock variables"
                                    :features="stockVariables" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-2 mb-sm-4">
                            <div class="card ml-md-4 h-100">
                                <variation-card cardClass="card-pricing border-0 mb-4" headerClass="text-white bg-light"
                                    buttonClass="bg-light border-light" header="Strategy variables"
                                    :features="strategyVariables" />
                            </div>
                        </div>
                        <div class="col-12 col-md-6 mb-2 mb-sm-4">
                            <div class="card mr-md-4 h-100">
                                <variation-card cardClass="card-pricing border-0 mb-4" headerClass="bg-success"
                                    buttonClass="bg-success border-success" header="Filter variables"
                                    :features="filterVariables" />
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import VariationCard from "./VariationCard.vue";
export default {
    name: "Variation",
    components: {
        VariationCard,
    },
    data() {
        return {
            executionVariables: '',
            strategyVariables: '',
            filterVariables: '',
            stockVariables: '',
            variationId: '',
        };
    },
    methods: {
        getVariations(variationId, type, variationType) {
            let data = {
                variationId: variationId,
                type: type,
            };
            this.$store.dispatch("variationStore/getVariationDetails", data, { root: true })
                .then(response => {
                    if (variationType === 'executionVariables') {
                        this.executionVariables = response.server.data.variables;
                    } else if (variationType === 'strategyVariables') {
                        this.strategyVariables = response.server.data.variables;
                    } else if (variationType === 'filterVariables') {
                        this.filterVariables = response.server.data.variables;
                    } else if (variationType === 'stockVariables') {
                        this.stockVariables = response.server.data.variables;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.variationId = this.$route.params.variationId;
        this.getVariations(this.variationId, 'EXECUTION', 'executionVariables');
        this.getVariations(this.variationId, 'STRATEGY', 'strategyVariables');
        this.getVariations(this.variationId, 'FILTER', 'filterVariables');
        this.getVariations(this.variationId, 'STOCK', 'stockVariables');
    },
};
</script>
