import requestCommon from "@/components/web-interface/requestCommon";
const state = {
    holidayDetails: null,
};
const actions = {
    getHolidayDetails(context, data) {
        return requestCommon.getInfo(
            "/holidays?" + "month=" + data.month + "&year=" + data.year,
            data,
            null,
            context,
            "getHolidayDetailsSuccessHandler",
            "getHolidayDetailsErrorHandler"
        );
    },
}
const mutations = {
    getHolidayDetailsSuccessHandler(state, data) {
        state.holidayDetails = data.server.data;
    },
    getHolidayDetailsErrorHandler(context, error) {
        console.log(error);
    }
};
const getters = {};
export const holidayStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}