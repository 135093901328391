<template>
    <transition name="order-slide">
        <div v-if="isVisible" class="sidenav sidenav-order fixed-right navbar-light  bg-white overflow-auto h-100">
            <button type="button" aria-label="Close" class="close mt-1" @click="closeSidebar">
                <span aria-hidden="true">×</span>
            </button>
            <div class="d-flex justify-content-center align-items-center p-2 bg-order-band">
                <h4 class="mb-0">Overview</h4>
            </div>
            <div class="d-flex ml-3 mt-1 align-items-center">
                <i class="fas fa-user mr-2"></i>
                <p class="mb-0 text-sm text-gray font-weight-bold">
                    {{ `${order.firstName} ${order.lastName}` }}
                </p>
            </div>
            <div class="d-flex ml-3 align-items-center">
                <i class="fas fa-briefcase mr-2"></i>
                <p class="mb-0 text-sm text-gray font-weight-bold">
                    {{ formatPortfolioName(order.portfolioName) }}
                </p>
            </div>
            <div class="d-flex ml-3 align-items-center">
                <i class="fas fa-hashtag mr-2"></i>
                <p class="mb-0 text-sm text-gray font-weight-bold">
                    {{ order.id }}
                </p>
            </div>
            <div class="p-3">
                <div v-if="entryOrders.length > 0">
                    <div class="mb-3 col-12 d-flex justify-content-center align-items-center bg-order-band">
                        <h5 class="mt-1">Entry Orders</h5>
                    </div>
                    <div v-for="(item, index) in entryOrders" :key="index">
                        <order-card :trigger="item.trigger" :optionsType="item.optionsType" :time="item.time"
                            :price="item.price" :status="item.status" :tradeDate="item.tradeDate"
                            :quantity="item.quantity" :instrument="item.tradingInstrument"
                            :orderId="item.orderConfirmId" :isEntry="true"></order-card>
                    </div>
                </div>
                <div v-if="exitOrders.length > 0" class="mb-3 mt-3">
                    <div class="mb-3 col-12 d-flex justify-content-center align-items-center bg-order-band">
                        <h5 class="mt-1">Exit Orders</h5>
                    </div>
                    <div v-for="(item, index) in exitOrders" :key="index">
                        <order-card :trigger="item.trigger" :exitType="item.exitType" :time="item.time"
                            :price="item.price" :status="item.status" :tradeDate="item.tradeDate"
                            :quantity="item.quantity" :instrument="item.tradingInstrument"
                            :orderId="item.orderConfirmId" :isEntry="false"></order-card>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import OrderCard from "./OrderCard.vue";
export default {
    name: "RightSidebar",
    components: {
        OrderCard,
    },
    props: {
        isVisible: {
            type: Boolean,
            default: false,
        },
        entryOrders: {
            type: Array,
            default: () => [],
        },
        exitOrders: {
            type: Array,
            default: () => [],
        },
        order: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        closeSidebar() {
            this.$emit("close");
        },
        formatPortfolioName(name) {
            return name
                .split('_')
                .map(word => {

                    return /^[a-zA-Z]+$/.test(word)
                        ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                        : word;
                })
                .join(' ');
        },
    },
};
</script>