<template>
    <div :class="['container container-shadow mt-2 order-curve-end ', cardClass]">
        <div class="row d-flex flex-grow-1 order-curve-end">
            <div class="col-9 p-0 d-flex flex-column">
                <div class="d-flex flex-grow-1 order-row-height">
                    <div class="col mt-1 ml-1 d-flex justify-content-start align-items-center">
                        <h6 class="mb-0 text-sm">{{ instrument }}</h6>
                    </div>
                </div>
                <hr class="horizontal order-hr-line dark my-1" />
                <div class="row d-flex flex-grow-1 order-row-height">
                    <div class="col-6 text-center">
                        <div class="col-12 justify-content-center d-flex">
                            <small class="text-xxs text-info font-weight-bold">
                                {{ quantity }}
                            </small>
                        </div>
                        <small class="mb-0 text-sm text-gray">Quantity</small>
                    </div>
                    <div class="col-6 text-center">
                        <div class="col-12 justify-content-center d-flex">
                            <small class="text-xxs text-green font-weight-bold">
                                ₹ {{ price }}
                            </small>
                        </div>
                        <small class="mb-0 text-sm text-gray">Price</small>
                    </div>
                </div>
                <hr class="horizontal order-hr-line dark my-1" />
                <div class="row d-flex flex-grow-1 order-row-height">
                    <div class="ml-3 col text-center">
                        <div class="justify-content-start d-flex">
                            <small class="text-xxs text-gray font-weight-bold">
                                <i class="fa fa-clock px-1"></i> {{ formattedTimestamp }}
                            </small>
                        </div>
                        <div v-if="orderId" class="justify-content-start d-flex">
                            <small class="text-xxs text-gray font-weight-bold">
                                <i class="fa fa-hashtag px-1"></i> {{ orderId }}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3 d-flex flex-column ">
                <div v-if="status === 'PENDING_CONFIRMED'"
                    class="row bg-danger order-curve-top text-center d-flex align-items-center d-flex flex-grow-1 ">
                    <small class="text-white font-weight-bold text-sm">
                        <span class="btn-inner--icon text-white font-weight-bold text-sm">
                            <i class="fas fa-redo mr-2 ml-3 "></i>
                        </span>
                        Retry
                    </small>
                </div>
                <div v-else
                    class="row bg-purple order-curve-top text-center d-flex align-items-center d-flex flex-grow-1">
                    <small class="mb-0 col text-sm text-white font-weight-bold">Exit</small>
                </div>
                <div v-if="trigger == 'BUY' || trigger == 'COVER'"
                    class="row d-flex flex-grow-1 bg-gradient-success text-center d-flex align-items-center row-fixed-height">
                    <small class="mb-0 col text-sm text-white font-weight-bold">{{ capitalizeWords(trigger) }}</small>
                </div>
                <div v-else-if="trigger == 'SHORT' || trigger == 'SELL'"
                    class="row d-flex flex-grow-1 bg-gradient-danger text-center d-flex align-items-center row-fixed-height">
                    <small class="mb-0 col text-sm text-white font-weight-bold">{{ capitalizeWords(trigger) }}</small>
                </div>
                <div :class="statusClass"
                    class="row order-curve-end text-center text-white d-flex flex-grow-1 align-items-center">
                    <small class="mb-0 col text-sm text-white font-weight-bold">{{ displayStatus }}</small>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "OrderCard",
    props: {
        trigger: { type: String, default: "" },
        optionsType: { type: String, default: "" },
        time: { type: String, default: "" },
        tradeDate: { type: String, default: "" },
        quantity: { type: Number, default: 0.0 },
        instrument: { type: String, default: "" },
        orderId: { type: String, default: "" },
        price: { type: Number, default: 0.0 },
        status: { type: Number, default: 0.0 },
        exitType: { type: String, default: "" },
        isEntry: { type: Boolean },
    },
    computed: {
        formattedTimestamp() {
            const date = new Date(`${this.tradeDate}T${this.time}`);
            const yyyy = date.getFullYear();
            const MM = String(date.getMonth() + 1).padStart(2, '0');
            const dd = String(date.getDate()).padStart(2, '0');
            const hh = String(date.getHours()).padStart(2, '0');
            const mm = String(date.getMinutes()).padStart(2, '0');
            const ss = String(date.getSeconds()).padStart(2, '0');
            const SSS = String(date.getMilliseconds()).padStart(3, '0');
            const timestamp = `${yyyy}-${MM}-${dd}T${hh}:${mm}:${ss}.${SSS}`;
            return timestamp;
        },
        displayStatus() {
            switch (this.status) {
                case "PENDING": return "P";
                case "PENDING_CONFIRMED": return "PC";
                case "INSUFFICIENT_QTY": return "IQ";
                case "CANCELLED": return "CN";
                case "COMPLETE": return "CM";
                case "REJECTED": return "R";
                case "OPEN": return "O";
                case "UPDATE": return "U";
                default: return this.status;
            }
        },
        statusClass() {
            switch (this.status) {
                case "PENDING": return 'bg-yellow';
                case "PENDING_CONFIRMED": return 'bg-gray';
                case "INSUFFICIENT_QTY": return 'bg-danger';
                case "CANCELLED": return 'bg-pink';
                case "COMPLETE": return 'bg-indigo';
                case "REJECTED": return 'bg-light';
                case "OPEN": return 'bg-teal';
                case "UPDATE": return 'bg-primary';
                default: return 'bg-secondary';
            }
        },
        cardClass() {
            return this.isEntry ? 'bg-entry' : 'bg-exit';
        }
    },
    methods: {
        capitalizeWords(value) {
            if (!value) return '';
            return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        }
    }
};
</script>
<style scoped></style>
