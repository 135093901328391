<template>
  <div class="content">
    <div class="container-fluid mt-4">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div class="row mt-3">
            <div class="col-12 col-xl-3 pr-0 d-flex align-content-between flex-wrap">
              <div class=" w-100 mb-3">
                <div class="px-3 px-xl-5 mt-3">
                  <h5>Select Month</h5>
                  <select v-model="selectedMonth" class="form-control">
                    <option v-for="(month, index) in months" :key="index" :value="index">{{ month }}</option>
                  </select>
                </div>
                <div class="px-3 px-xl-5 mt-3">
                  <h5>Select Year</h5>
                  <select v-model="selectedYear" class="form-control">
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                  </select>
                </div>
                <div class="d-flex justify-content-center align-items-center mt-4 mb-3">
                  <base-button size="md" type="primary" @click="updateCalendar">Search</base-button>
                  <!-- <button @click="updateCalendar" class="btn btn-white text-primary">Search</button> -->
                </div>
              </div>
              <div class="container-fluid">
                <div class=" mt-3">
                  <badge v-for="(color, asset) in assetColors" :key="asset" class="badge-dot mr-4" type="">
                    <i :class="`bg-` + color"></i>
                    <span class="status">{{ toCamelCase(asset) }}</span>
                  </badge>
                </div>
              </div>
            </div>
            <div class="col-12 col-xl-9 pl-0">
              <div class="container-fluid h-100">
                <div class="row h-100">
                  <div class="col h-100">
                    <div class="card pl-0 card-calendar h-100">
                      <div class="card-header">
                        <h2 class="mb-0">Calendar</h2>
                        <h5 class="mb-0">{{ months[displayMonth] }} {{ displayYear }}</h5>
                      </div>
                      <div class="card-body p-0 card-calendar-body h-100">
                        <div id="fullCalendar" class="h-100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridDay from "@fullcalendar/timegrid";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import Badge from "@/components/Badge.vue";

const today = new Date();

export default {
  name: "calendar",
  components: {
    RouteBreadCrumb,
    Badge
  },
  data() {
    return {
      selectedMonth: today.getMonth(),
      selectedYear: today.getFullYear(),
      displayMonth: today.getMonth(),
      displayYear: today.getFullYear(),
      months: [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      years: Array.from({ length: 20 }, (_, i) => today.getFullYear() - i),
      assetColors: {
        "EQUITY": "danger",
        "INDEX": "primary",
        "CURRENCY": "success",
        "COMMODITY": "default",
        "US_EQUITY": "warning"
      },
    };
  },
  computed: {
    ...mapState({
      holidays: state => state.holidayStore.holidayDetails
    })
  },
  mounted() {
    this.initCalendar();
    this.updateCalendar();
  },
  methods: {
    ...mapActions("holidayStore", ["getHolidayDetails"]),
    toCamelCase(str) {
      return str.toLowerCase().replace(/(_\w)/g, match => match[1].toUpperCase());
    },
    initCalendar() {
      var calendarEl = document.getElementById("fullCalendar");
      this.calendar = new Calendar(calendarEl, {
        plugins: [dayGridPlugin, timeGridDay, interactionPlugin],
        selectable: true,
        headerToolbar: false,
        events: [],
        eventContent: (info) => {
          const assetColors = this.assetColors;
          const events = info.event.extendedProps.groupedEvents;
          const dots = events.map(event =>
            `<div class="rounded-circle bg-${assetColors[event.asset]} d-inline-block mr-1" style="width: 10px; height: 10px;"></div>`
          ).join('');
          return {
            html: `<div class="bg-white mb-1 d-flex align-items-center">${dots}</div>`
          };
        },
      });
      this.calendar.render();
    },
    async updateCalendar() {
      this.displayMonth = this.selectedMonth;
      this.displayYear = this.selectedYear;
      const data = { month: this.displayMonth + 1, year: this.displayYear };
      await this.getHolidayDetails(data);
      if (this.holidays) {
        const groupedHolidays = this.holidays.reduce((acc, holiday) => {
          const date = holiday.day;
          if (!acc[date]) acc[date] = [];
          acc[date].push(holiday);
          return acc;
        }, {});
        const events = Object.keys(groupedHolidays).map(date => ({
          title: '',
          start: date,
          className: 'holiday',
          extendedProps: {
            groupedEvents: groupedHolidays[date]
          }
        }));
        this.calendar.removeAllEvents();
        this.calendar.addEventSource(events);
        const startDate = `${this.displayYear}-${String(this.displayMonth + 1).padStart(2, '0')}-01`;
        this.calendar.gotoDate(startDate);
        this.calendar.render();
      }
    },
  },
  beforeUnmount() {
    if (this.calendar) {
      this.calendar.destroy();
    }
  },
};
</script>
