import requestCommon from "@/components/web-interface/requestCommon";
const state = {
    brokerDetails: null,
};
const actions = {
    getBrokerDetails(context, data) {
        let fill = "";
        if (data.filter) {
            fill = "filter=" + data.filter
        }
        return requestCommon.getInfo(
            "/brokers?" + fill + "&direction=" + data.direction + "&pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&sortBy=" + data.sortBy,
            data,
            null,
            context,
            "getBrokerDetailsSuccessHandler",
            "getBrokerDetailsErrorHandler"
        );
    },
}
const mutations = {
    getBrokerDetailsSuccessHandler(state, data) {
        state.brokerDetails = data.server.data;
    },
    getBrokerDetailsErrorHandler(context, error) {
        console.log(error);
    }
};
const getters = {};
export const brokerStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}