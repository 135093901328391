<template>
  <div class="wrapper ">
    <!-- <notifications></notifications> -->
    <side-bar class="side-bar" v-if="!$route.meta.hideSidebar">
      <template v-slot:links>
        <sidebar-item :link="{
      name: 'System',
      icon: 'ni ni-shop text-red',
      path: '/system/users',
    }">
          <sidebar-item :link="{
      name: 'Users',
      path: '/system/users',
    }">
          </sidebar-item>
          <sidebar-item :link="{
      name: 'Brokers',
      path: '/system/brokers',
    }">
          </sidebar-item>
          <sidebar-item :link="{
      name: 'Holidays',
      path: '/system/holidays',
    }">
          </sidebar-item>

          <sidebar-item :link="{
      name: 'Instruments',
      path: '/system/instruments',
    }">
          </sidebar-item>
          <sidebar-item :link="{
      name: 'Portfolios',
      path: '/system/portfolios',
    }">
          </sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{
      name: 'Reports',
      icon: 'ni ni-chart-pie-35 text-info',
    }">
          <sidebar-item :link="{ name: 'Orders', path: '/report/orders' }"></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{ name: 'Settings', icon: 'ni ni-settings-gear-65', path: '/buttonsPage/setting' }"></sidebar-item>

      </template>

      <template v-slot:links-after>
        <ul class="navbar-nav position-absolute bottom-0 w-100 mb-md-3">
          <li :class="['nav-item', backgroundClass]" class="mb-1" @click="togglePlayPause">
            <a class="nav-link">
              <i :class="iconClasses" class="text-white"></i>
              <span :class="textClasses" class="text-white nav-link-text">{{ toggleText }}</span>
            </a>
          </li>

          <li class="nav-item bg-red text-white">
            <a class="nav-link">
              <i class="fas fa-power-off text-white "></i>
              <span class="nav-link-text text-white">Turn Off</span>
            </a>
          </li>
        </ul>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      isPlaying: false,
      isIconOnly: true,
      nn: this.$sidebar.showSidebar

    }
  },
  computed: {
    toggleText() {
      return this.isPlaying ? 'Pause' : 'Play';
    },
    iconClasses() {
      return this.isPlaying
        ? 'fas fa-pause '
        : 'fas fa-play ';
    },
    backgroundClass() {
      return this.isPlaying
        ? 'bg-purple'
        : 'bg-green';
    },
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },

    togglePlayPause() {
      this.isPlaying = !this.isPlaying;
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
